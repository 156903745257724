import * as PAINTED_ICONS from './paintedIcons';
import * as PLAIN_ICONS from './plainIcons';

export { PAINTED_ICONS, PLAIN_ICONS };

type PlainIconNames = keyof typeof PLAIN_ICONS;
type PaintedIconNames = keyof typeof PAINTED_ICONS;

export type IconName = PlainIconNames | PaintedIconNames;

const PLAIN_ICON_NAMES = Object.keys(PLAIN_ICONS) as PlainIconNames[];

const PAINTED_ICON_NAMES = Object.keys(PAINTED_ICONS) as PaintedIconNames[];

const ALL_ICON_NAMES = [
  ...PLAIN_ICON_NAMES,
  ...PAINTED_ICON_NAMES,
] as IconName[];

export function isIconName(name: string): name is IconName {
  return (ALL_ICON_NAMES as string[]).includes(name);
}
