import airbyte_brand from './icons/airbyte.svg?react';
import auth0_brand from './icons/auth0.svg?react';
import auth0_dark from './icons/auth0_icon_dark.svg?react';
import auth0_light from './icons/auth0_icon_light.svg?react';
import autosuspend_gradient_base from './icons/autosuspend_gradient.svg?react';
import aws_incognito_dark from './icons/aws_incognito_dark.svg?react';
import aws_incognito_light from './icons/aws_incognito_light.svg?react';
import branch_gradient_base from './icons/branch_gradient.svg?react';
import clerk_brand from './icons/clerk.svg?react';
import clerk_icon_dark from './icons/clerk_icon_dark.svg?react';
import clerk_icon_light from './icons/clerk_icon_light.svg?react';
import clickhouse_dark from './icons/clickhouse_dark.svg?react';
import clickhouse_light from './icons/clickhouse_light.svg?react';
import cloudflare_brand from './icons/cloudflare.svg?react';
import compute_size_autoscale_gradient_base from './icons/compute_size_autoscale_gradient.svg?react';
import compute_time_beta_base from './icons/compute_time_beta_base.svg?react';
import compute_time_gradient_base from './icons/compute_time_gradient.svg?react';
import confluent_dark from './icons/confluent_dark.svg?react';
import confluent_light from './icons/confluent_light.svg?react';
import database_gradient_base from './icons/database_gradient.svg?react';
import datadog_dark from './icons/datadog_dark.svg?react';
import datadog_light from './icons/datadog_light.svg?react';
import deno_dark from './icons/deno_dark.svg?react';
import deno_light from './icons/deno_light.svg?react';
import descope_auth_dark from './icons/descope_dark.svg?react';
import descope_auth_light from './icons/descope_light.svg?react';
import done_gradient_base from './icons/done_gradient.svg?react';
import exograph_brand from './icons/exograph.svg?react';
import ferretdb_dark from './icons/ferretdb_dark.svg?react';
import ferretdb_light from './icons/ferretdb_light.svg?react';
import fivetran_brand from './icons/fivetran.svg?react';
import github_dark from './icons/github_dark.svg?react';
import github_light from './icons/github_light.svg?react';
import google_brand from './icons/google.svg?react';
import grafbase_dark from './icons/grafbase_dark.svg?react';
import grafbase_light from './icons/grafbase_light.svg?react';
import graph_gradient_base from './icons/graph_gradient.svg?react';
import hasura_brand from './icons/hasura.svg?react';
import hasura_mono_dark from './icons/hasura_mono_dark.svg?react';
import hasura_mono_light from './icons/hasura_mono_light.svg?react';
import heroku_brand from './icons/heroku.svg?react';
import hint_dark from './icons/hint_dark.svg?react';
import hint_light from './icons/hint_light.svg?react';
import koyeb_dark from './icons/koyeb_dark.svg?react';
import koyeb_light from './icons/koyeb_light.svg?react';
import materialize_dark from './icons/materialize_dark.svg?react';
import materialize_light from './icons/materialize_light.svg?react';
import microsoft_brand from './icons/microsoft.svg?react';
import neosync_dark from './icons/neosync_dark.svg?react';
import neosync_light from './icons/neosync_light.svg?react';
import netlify_dark from './icons/netlify_dark.svg?react';
import netlify_light from './icons/netlify_light.svg?react';
import okta_dark from './icons/okta_dark.svg?react';
import okta_light from './icons/okta_light.svg?react';
import other_dark from './icons/other_dark.svg?react';
import other_light from './icons/other_light.svg?react';
import outerbase_dark from './icons/outerbase_dark.svg?react';
import outerbase_light from './icons/outerbase_light.svg?react';
import person_dark from './icons/person_dark.svg?react';
import person_light from './icons/person_light.svg?react';
import prisma_brand from './icons/prisma.svg?react';
import railway_dark from './icons/railway_dark.svg?react';
import railway_light from './icons/railway_light.svg?react';
import render_brand from './icons/render.svg?react';
import roles_gradient_base from './icons/roles_gradient.svg?react';
import sequin_brand from './icons/sequin.svg?react';
import stack_auth_dark from './icons/stack_auth_dark.svg?react';
import stack_auth_light from './icons/stack_auth_light.svg?react';
import stepzen_dark from './icons/stepzen_dark.svg?react';
import stepzen_light from './icons/stepzen_light.svg?react';
import stytch_dark from './icons/stytch_dark.svg?react';
import stytch_light from './icons/stytch_light.svg?react';
import vercel_brand from './icons/vercel.svg?react';
import vercel_brand_light from './icons/vercel_light.svg?react';
import written_data_gradient_base from './icons/written_data_gradient.svg?react';
import wundergraph_dark from './icons/wundergraph_dark.svg?react';
import wundergraph_light from './icons/wundergraph_light.svg?react';

export const hasura_mono = {
  dark: hasura_mono_dark,
  light: hasura_mono_light,
} as const;

export const hint = {
  dark: hint_dark,
  light: hint_light,
} as const;

export const person = {
  dark: person_dark,
  light: person_light,
} as const;

export const github = {
  dark: github_dark,
  light: github_light,
} as const;

export const railway = {
  dark: railway_dark,
  light: railway_light,
} as const;

export const deno = {
  dark: deno_dark,
  light: deno_light,
} as const;

export const confluent = {
  dark: confluent_dark,
  light: confluent_light,
} as const;

export const grafbase = {
  dark: grafbase_dark,
  light: grafbase_light,
} as const;

export const outerbase = {
  dark: outerbase_dark,
  light: outerbase_light,
} as const;

export const okta = {
  dark: okta_dark,
  light: okta_light,
} as const;

export const koyeb = {
  dark: koyeb_dark,
  light: koyeb_light,
} as const;

export const netlify = {
  dark: netlify_dark,
  light: netlify_light,
} as const;

export const wundergraph = {
  dark: wundergraph_dark,
  light: wundergraph_light,
} as const;

export const neosync = {
  dark: neosync_dark,
  light: neosync_light,
} as const;

export const clickhouse = {
  dark: clickhouse_dark,
  light: clickhouse_light,
} as const;

export const materialize = {
  dark: materialize_dark,
  light: materialize_light,
} as const;

export const ferretdb = {
  dark: ferretdb_dark,
  light: ferretdb_light,
} as const;

export const stepzen = {
  dark: stepzen_dark,
  light: stepzen_light,
} as const;

export const cloudflare = {
  dark: cloudflare_brand,
  light: cloudflare_brand,
} as const;

export const airbyte = {
  dark: airbyte_brand,
  light: airbyte_brand,
} as const;

export const sequin = {
  dark: sequin_brand,
  light: sequin_brand,
} as const;

export const exograph = {
  dark: exograph_brand,
  light: exograph_brand,
} as const;

export const hasura = {
  dark: hasura_brand,
  light: hasura_brand,
} as const;

export const microsoft = {
  dark: microsoft_brand,
  light: microsoft_brand,
} as const;

export const fivetran = {
  dark: fivetran_brand,
  light: fivetran_brand,
} as const;

export const clerk = {
  dark: clerk_brand,
  light: clerk_brand,
} as const;

export const auth0 = {
  dark: auth0_brand,
  light: auth0_brand,
} as const;

export const heroku = {
  dark: heroku_brand,
  light: heroku_brand,
} as const;

export const render = {
  dark: render_brand,
  light: render_brand,
} as const;

export const prisma = {
  dark: prisma_brand,
  light: prisma_brand,
} as const;

export const google = {
  dark: google_brand,
  light: google_brand,
} as const;

export const vercel = {
  dark: vercel_brand,
  light: vercel_brand_light,
} as const;
export const vercelmp = vercel;

export const datadog = {
  dark: datadog_dark,
  light: datadog_light,
} as const;

export const graph_gradient = {
  dark: graph_gradient_base,
  light: graph_gradient_base,
} as const;

export const branch_gradient = {
  dark: branch_gradient_base,
  light: branch_gradient_base,
} as const;

export const autosuspend_gradient = {
  dark: autosuspend_gradient_base,
  light: autosuspend_gradient_base,
} as const;

export const compute_size_autoscale_gradient = {
  dark: compute_size_autoscale_gradient_base,
  light: compute_size_autoscale_gradient_base,
} as const;

export const compute_time_gradient = {
  dark: compute_time_gradient_base,
  light: compute_time_gradient_base,
} as const;

export const written_data_gradient = {
  dark: written_data_gradient_base,
  light: written_data_gradient_base,
} as const;

export const database_gradient = {
  dark: database_gradient_base,
  light: database_gradient_base,
} as const;

export const roles_gradient = {
  dark: roles_gradient_base,
  light: roles_gradient_base,
} as const;

export const compute_time_beta = {
  dark: compute_time_beta_base,
  light: compute_time_beta_base,
} as const;

export const clerk_icon = {
  dark: clerk_icon_dark,
  light: clerk_icon_light,
} as const;

export const stytch = {
  dark: stytch_dark,
  light: stytch_light,
} as const;

export const aws_incognito = {
  dark: aws_incognito_dark,
  light: aws_incognito_light,
} as const;

export const auth0_icon = {
  dark: auth0_dark,
  light: auth0_light,
} as const;

export const stack_auth = {
  dark: stack_auth_dark,
  light: stack_auth_light,
} as const;

export const descope_auth = {
  dark: descope_auth_dark,
  light: descope_auth_light,
} as const;

export const done_gradient = {
  dark: done_gradient_base,
  light: done_gradient_base,
} as const;

export const other = {
  dark: other_dark,
  light: other_light,
} as const;
